import {Injectable} from '@angular/core';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as VehicleMaintenanceActions from '../vehicle-maintenance/vehicle-maintenance.actions';
import {
  UpdatedMaintenanceVehiclesAction,
  UpdatedOpenTasksAction,
  UpdatedVehicleMaintenanceSearchActiveCategoryAction,
  UpdatedVehicleMaintenanceSearchConfigAction,
  UpdatedVehicleMaintenanceSearchPageSettings,
  UpdateVehicleMaintenanceSearchConfigAction,
  UpdatedVehicleAction,
  UpdatedVehicleMaintenanceSearchSelectedFiltersAction,
  UpdateMaintenanceVehiclesAction,
  UpdateVehicleAction, UpdateEnforcedAuctionBaseVehicleAction, UpdatedEnforcedAuctionBaseVehicleAction,
  UpdatedUserSubstitutionsAction, UpdatedVehicleInListAction,
  UpdateUserSubstitutionsAction, UpdateVehicleInListAction
} from './vehicle-maintenance.actions';
import {VehicleMaintenanceService} from '../../service/vehicle-maintenance.service';
import {SpinnerService} from '../../service/spinner.service';
import {VehicleMaintenanceSearchService} from '../../service/vehicle-maintenance-search.service';
import {SystemSettingsService} from '../../service/system-settings.service';
import {UserService} from '../../service/user.service';
import {EnforcedAuctionService} from '../../service/enforced-auction.service';

@Injectable()
export class VehicleMaintenanceEffects {
  id: number; // used for UpdateOfferAction
  userSubstitutionsInitialized: boolean;

  updateVehicle$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATE_VEHICLE))
      .pipe(map((action: UpdateVehicleAction) => action))
      .pipe(tap(payload => this.id = payload.id))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.vehicleMaintenanceService
            .getVehicle(this.id)
            .pipe(map(data => new UpdatedVehicleAction(data))))
      ))
  );

  updateVehicleInList$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATE_VEHICLE_IN_LIST))
      .pipe(map((action: UpdateVehicleInListAction) => action))
      .pipe(
        switchMap((action) => {
          if (action.isEnforcedAuction) {
            return this.spinnerService.spinner(
              this.enforcedAuctionService.getEnforcedVehicleBase(action.id)
                .pipe(
                  map(data => {
                    return new UpdatedVehicleInListAction(data);
                  })
                )
            );
          }
          //implement here for VehicleBase if needed
          return of(undefined);
        })
      )
  );

  updateMaintenanceVehicles$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATE_MAINTENANCE_VEHICLES))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.vehicleMaintenanceSearchService.getVehiclesByFilter()).pipe(
          map(data => new UpdatedMaintenanceVehiclesAction(data))
        )
      ))
  );

  updateEnforcedAuctionVehicles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(VehicleMaintenanceActions.UPDATE_MAINTENANCE_VEHICLES))
      .pipe(map((action: UpdateMaintenanceVehiclesAction) => action))
      .pipe(switchMap(() =>
        this.vehicleMaintenanceSearchService
          .getEnforcedAuctionVehicles()
          .pipe(map(data => {
            return new UpdatedMaintenanceVehiclesAction(data);
          }))
      )));

  updateEnforcedAuctionVehicle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATE_ENFORCED_AUCTION_VEHICLE))
      .pipe(map((action: UpdateEnforcedAuctionBaseVehicleAction) => action))
      .pipe(map(data => {
        return new UpdatedEnforcedAuctionBaseVehicleAction(data.payload);
      }
      )));

  updateMaintenanceVehiclesOnFilterSelect$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATED_VEHICLE_MAINTENANCE_SEARCH_SELECTED_FILTERS),
      map((action: UpdatedVehicleMaintenanceSearchSelectedFiltersAction) => {
        if (this.supportsLocalStorage()) {
          let currentCategory: Section;
          let categoryFilter: CategoryFilterDto[];
          currentCategory = JSON.parse(localStorage.getItem(UserService.CURRENT_CATEGORY + this.userService.userId));
          // get from localStorage and set filters
          if (currentCategory) {
            categoryFilter = JSON.parse(localStorage.getItem(UserService.CATEGORY_FILTER + this.userService.userId +
            '-' + action.channel));
            categoryFilter.find(value => value.category === currentCategory).filter = action.filters;
            this.userService.storeCategoryFilter(categoryFilter, action.channel);
          }
        }
        return new UpdateMaintenanceVehiclesAction(action.channel);
      }))
  );

  /**
   * We are filtering for non-null categories, as category null means, that text has been entered in the search field
   */
  updateMaintenanceVehiclesOnSectionSelect$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATED_VEHICLE_MAINTENANCE_SEARCH_ACTIVE_CATEGORY),
      filter((act: UpdatedVehicleMaintenanceSearchActiveCategoryAction) => act.payload !== null),
      map((action: UpdatedVehicleMaintenanceSearchActiveCategoryAction) => {
        const currentCategoryFilter = {} as CategoryFilterDto;
        currentCategoryFilter.category = action.payload;

        let selectedFilters = {} as SearchFilter[];
        let categoryFilter = {} as CategoryFilterDto[];

        if (this.supportsLocalStorage()) {
          categoryFilter = JSON.parse(localStorage.getItem(UserService.CATEGORY_FILTER + this.userService.userId +
        '-' + action.channel));
          // check if the current category already added
          if (!categoryFilter) {
            categoryFilter = [];
            categoryFilter.push(currentCategoryFilter);
          } else {
            if (categoryFilter.filter(value => value.category === action.payload).length === 0) {
              if (action.payload==='LONGTIME_PENDING') {
                currentCategoryFilter.filter = [];
                currentCategoryFilter.filter.push('SHOW_NOT_SOLD_VEHICLE');
              }
              categoryFilter.push(currentCategoryFilter);
            }
          }
          this.userService.storeCategoryFilter(categoryFilter, action.channel);

          selectedFilters = categoryFilter.find(value => value.category === action.payload).filter;
          if (!selectedFilters) {
            selectedFilters = [];
          }
        }
        this.userService.storeCurrentCategory(action.payload);
        return new UpdatedVehicleMaintenanceSearchSelectedFiltersAction(selectedFilters, action.channel);
      }))
  );

  updatePageSettings$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATED_VEHICLE_MAINTENANCE_SEARCH_PAGE_SETTINGS),
      map((action: UpdatedVehicleMaintenanceSearchPageSettings) => {
        return new UpdateVehicleMaintenanceSearchConfigAction(action.pageSettings.channel);
      }
      ))
  );

  updateSearchConfig$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATE_VEHICLE_MAINTENANCE_SEARCH_CONFIG),
      switchMap((action: UpdateVehicleMaintenanceSearchConfigAction) => {

        const SearchFilterConfigObservable = this.systemSettingsService.getSystemFeatureSettingForChannel(
          'VEHICLE_MAINTENANCE.enabled_search_filters', action.channel);
        const enforcedAuctionMaintenanceSettingsObservable = this.systemSettingsService.getSystemFeatureSettingForChannel(
          'ENFORCED_AUCTION.maintenance', action.channel);

        return combineLatest([SearchFilterConfigObservable, enforcedAuctionMaintenanceSettingsObservable])
          .pipe(map(([searchFilterConfig, enforcedAuctionMaintenanceSettings]) => {
            let parsedSearchFilterConfig: SearchFilterConfig;
            let parsedEnforcedAuctionMaintenanceSettings: EnforcedAuctionMaintenanceSettings;
            try {
              parsedSearchFilterConfig = JSON.parse(searchFilterConfig);
            } catch (e) {
              parsedSearchFilterConfig = undefined;
            }
            try {
              parsedEnforcedAuctionMaintenanceSettings = JSON.parse(enforcedAuctionMaintenanceSettings);
            } catch (e) {
              parsedEnforcedAuctionMaintenanceSettings = undefined;
            }
            try {
              if (action.channel === 'PIA') {
                if (parsedSearchFilterConfig && parsedEnforcedAuctionMaintenanceSettings) {
                  parsedSearchFilterConfig.filterConfig['LONGTIME_PENDING'] = parsedEnforcedAuctionMaintenanceSettings.enabledSearchFilters;
                } else if (parsedEnforcedAuctionMaintenanceSettings) {
                  parsedSearchFilterConfig = {
                    filterConfig: {['LONGTIME_PENDING']: parsedEnforcedAuctionMaintenanceSettings.enabledSearchFilters}
                  };
                }
              }
            } catch (e) {
              // intentionally left empty
            }

            return new UpdatedVehicleMaintenanceSearchConfigAction(parsedSearchFilterConfig);
          }));
      }
      ))
  );

  updateOpenTasks$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(VehicleMaintenanceActions.UPDATE_OPEN_TASKS),
      switchMap(() =>
        this.vehicleMaintenanceService.getOpenTasks()
          .pipe(map(data => {
            return new UpdatedOpenTasksAction(data);
          })
          )
      ))
  );

  initUserSubstitutions$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(VehicleMaintenanceActions.INIT_USER_SUBSTITUTIONS),
    switchMap(() => {
      if (!this.userSubstitutionsInitialized) {
        return this.userService.activateDefaultSubstitutionBehavior()
          .pipe(map(data => {
            this.userSubstitutionsInitialized = true;
            return new UpdatedUserSubstitutionsAction(data);
          }));
      }
      return this.userService.getSubstitutions()
        .pipe(map(data => {
          return new UpdatedUserSubstitutionsAction(data);
        }));
    }))
  );

  updateUserSubstitutions$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(VehicleMaintenanceActions.UPDATE_USER_SUBSTITUTIONS),
    switchMap((action: UpdateUserSubstitutionsAction) => {
      return this.userService.updateSubstitutions(action.payload)
        .pipe(map(data => {
          return new UpdatedUserSubstitutionsAction(data);
        }));
    }))
  );

  /**
   * Check if the current device supports local storage.
   * @returns {boolean} If the current device supports local storage.
   */
  private supportsLocalStorage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  }

  constructor(private vehicleMaintenanceService: VehicleMaintenanceService,
              private vehicleMaintenanceSearchService: VehicleMaintenanceSearchService,
              private spinnerService: SpinnerService,
              private systemSettingsService: SystemSettingsService,
              private userService: UserService,
              private enforcedAuctionService: EnforcedAuctionService,
              private actions$: Actions) {
  }
}
