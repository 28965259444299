import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {Injectable, NgModule} from '@angular/core';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {AppRoutesModule} from './app.routes';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducers} from './shared/store/app.reducers';
import {SearchEffects} from './shared/store/search/search.effects';
import {OfferEffects} from './shared/store/offer/offer.effects';
import {UserEffects} from './shared/store/user/user.effects';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {httpInterceptorProviders} from './http-interceptor-providers';
import {windowProviders} from './window-providers';
import {registerLocaleData} from '@angular/common';
import {ClipboardModule} from '@angular/cdk/clipboard';
// Angular requires manual import of all locales in use, it only knows en-US by default
// see https://angular.io/guide/i18n#i18n-pipes for further information
import localeDe from '@angular/common/locales/de';
import localeSl from '@angular/common/locales/sl';
import localeBs from '@angular/common/locales/bs';
import localeBg from '@angular/common/locales/bg';
import localeHr from '@angular/common/locales/hr';
import localeCs from '@angular/common/locales/cs';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeMk from '@angular/common/locales/mk';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeSr from '@angular/common/locales/sr';
import localeSk from '@angular/common/locales/sk';
import localeEs from '@angular/common/locales/es';
import localeUk from '@angular/common/locales/uk';
import {LoginGuard} from './shared/component/auth/login-guard/LoginGuard';
import {VehicleMaintenanceEffects} from './shared/store/vehicle-maintenance/vehicle-maintenance.effects';
import {BuyerGuard} from './shared/component/auth/buyer-guard/BuyerGuard';
import {OfferMaintenanceGuard} from './shared/component/auth/offer-maintenance-guard/OfferMaintenanceGuard';
import {VehicleMaintenanceGuard} from './shared/component/auth/vehicle-maintenance-guard/VehicleMaintenanceGuard';
import {NotLockedGuard} from './shared/component/auth/not-locked-guard/NotLockedGuard';
import {AdminGuard} from './shared/component/auth/admin-guard/AdminGuard';
import {DevGuard} from './shared/component/auth/dev-guard/DevGuard';
import {GtcGuard} from './shared/component/auth/gtc-guard/GtcGuard';
import {DealerSettingsGuard} from './shared/component/auth/dealer-settings-guard/DealerSettingsGuard';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {RxStompService} from './shared/service/rx-stomp.service';
import {SalesOfferEffects} from './shared/store/sales-offer/sales-offer.effects';
import {CanDeactivateGuard} from './shared/component/auth/can-deactivate-guard/CanDeactivateGuard';
import {NotificationEffects} from './shared/store/notification/notification.effects';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {EnforcedAuctionOfferEffects} from './shared/store/enforced-auction-offer/enforced-auction-offer.effects';
import {OfferMaintenanceEffects} from './shared/store/maintenance-offers/maintenance-offer.effects';
import {UserSettingsEffects} from './shared/store/user/user-settings/user-settings.effects';
import {ToastContainerComponent} from './standalone/toast-container/toast-container.component';

export const conditionalImports = [];

registerLocaleData(localeDe);
registerLocaleData(localeSl);
registerLocaleData(localeBs);
registerLocaleData(localeBg);
registerLocaleData(localeHr);
registerLocaleData(localeCs);
registerLocaleData(localeFr);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localeMk);
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeRo);
registerLocaleData(localeSr);
registerLocaleData(localeSk);
registerLocaleData(localeEs);
registerLocaleData(localeUk);

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: {enable: false},
    rotate: {enable: false}
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent
  ],
  bootstrap: [AppComponent],
  imports: [BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    ClipboardModule,
    NgbAlertModule,
    CarouselModule.forRoot(),
    TypeaheadModule.forRoot(),
    ButtonsModule.forRoot(),
    AppRoutesModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([SearchEffects, OfferEffects, EnforcedAuctionOfferEffects, UserEffects, UserSettingsEffects, VehicleMaintenanceEffects, SalesOfferEffects, NotificationEffects, OfferMaintenanceEffects]),
    ...conditionalImports,
    ToastContainerComponent], providers: [
    httpInterceptorProviders,
    windowProviders,
    LoginGuard,
    AdminGuard,
    DevGuard,
    BuyerGuard,
    DealerSettingsGuard,
    NotLockedGuard,
    VehicleMaintenanceGuard,
    OfferMaintenanceGuard,
    GtcGuard,
    CanDeactivateGuard,
    RxStompService,
    {provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig}
    // ,{
    // This is how we can access our app config (config.json) as a provider, should we need it in the future
    // cf. https://stackoverflow.com/questions/43193049/app-settings-the-angular-way/62057120#62057120
    //   provide: <TARGET>,
    //   useFactory: (config: AppConfig) => config.<property>,
    //   deps: [APP_CONFIG]
    // }
    ,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
