import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule, conditionalImports} from './app/app.module';
import {APP_CONFIG} from './app/shared/misc/inject-tokens';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AppConfig} from './app/shared/model/app-config.model';

const request = new XMLHttpRequest();
request.addEventListener('load', configListener);
request.addEventListener('error', configFailed);
request.open('GET', './assets/config/config.json');
request.send();

function configListener(response: any) {
  try {
    const configuration: AppConfig = JSON.parse(response.target.responseText);

    if (configuration.production) {
      enableProdMode();
    } else {
      conditionalImports.push(StoreDevtoolsModule.instrument());
    }

    // pass config to bootstrap process using an injection token
    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: configuration }
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));

  } catch (error) {
    console.error(error);
  }
}

function configFailed() {
  console.error('Error retrieving config.json');
}
