import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {NotFoundInterceptor} from './sales/offer/offer-detail/offer-detail-interceptor/NotFoundInterceptor';
import {GeneralInterceptor} from './shared/component/error/interceptor/GeneralInterceptor';
import {AuthInterceptor} from './shared/component/auth/auth-interceptor/AuthInterceptor';

export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: NotFoundInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: GeneralInterceptor, multi: true}
];

