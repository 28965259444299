import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {first, map, switchMap} from 'rxjs/operators';
import * as MaintenanceOfferActions from './maintenance-offer.actions';
import {Injectable} from '@angular/core';
import {OfferService} from '../../service/offer.service';
import {SearchService} from '../../service/search.service';
import * as fromRoot from '../app.reducers';
import {SpinnerService} from '../../service/spinner.service';
import * as SpinnerActions from '../spinner/spinner.actions';


@Injectable()
export class OfferMaintenanceEffects {
  size: number;
  page: number;
  sort: string;
  order: string;
  id: number; // used for UpdateOfferAction
  channel: DistributionChannel;


  updateOffers$: Observable<Action> = createEffect(
    () => this.actions$.pipe(ofType(MaintenanceOfferActions.UPDATE_OFFERS))
      .pipe(switchMap(() =>
        this.store.select(fromRoot.getPageSettings).pipe(first())
          .pipe(
            switchMap((page) =>
              this.offerService.findOffersForMaintenance(page.size, page.page, page.sort, page.order)
                .pipe(map(data => new MaintenanceOfferActions.UpdatedOffersAction(data)))
            )
          ))
      )
  );

  updateChannelOffersPageOnPageSettingChange$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(MaintenanceOfferActions.UPDATE_PAGE_SETTINGS))
      .pipe(map((action: MaintenanceOfferActions.UpdatePageSettings) => action.payload))
      .pipe(
        switchMap((page) =>
          this.spinnerService.spinner(
            this.offerService.findOffersForMaintenance(page.size, page.page, page.sort, page.order)
              .pipe(
                map(data => {
                  return new MaintenanceOfferActions.UpdatedOffersAction(data);
                })
              ))))
  );

  updateLoadingActionOnUpdatedOffersChange$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(MaintenanceOfferActions.UPDATED_OFFERS))
      .pipe((map(() => new SpinnerActions.UpdateLoadingAction(false))))
  );

  constructor(private offerService: OfferService,
              private searchService: SearchService,
              private store: Store<fromRoot.AppState>,
              private actions$: Actions,
              private spinnerService: SpinnerService) {
  }
}
