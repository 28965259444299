import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SearchService} from '../../service/search.service';
import * as SearchActions from './search.actions';
import {UpdatedSearchAggregationAction} from './search.actions';
import * as fromRoot from '../app.reducers';

/**
 * Effects contain the side effects for asynchronous calls so that our components can remain compact
 * We are listening on the Observable of all search actions until SearchActions.UPDATE_SEARCH_AGGREGATION is dispatched
 * on which we act here to call the SearchService to fetch the new search aggregation from the rest endpoint
 * We will then dispatch our own SearchActions.UPDATED_AMOUNT which will be processed in the reducer
 */
@Injectable()
export class SearchEffects {
  update$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.UPDATE_SEARCH_AGGREGATION)).pipe(
      switchMap(() =>
        this.searchService
          .getSearchAggregations()
          .pipe(map(data => new UpdatedSearchAggregationAction(data)))
      ))
  );

  constructor(
      private searchService: SearchService,
      private store: Store<fromRoot.AppState>,
      private actions$: Actions
  ) {
  }
}
