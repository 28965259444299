import {Component} from '@angular/core';
import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ToastAlertService} from '../../shared/service/toast-alert.service';

@Component({
  standalone: true,
  selector: 'ucs-toast-container',
  templateUrl: './toast-container.component.html',
  imports: [
    NgbToastModule, SharedModule
  ],
  styleUrls: ['./toast-container.component.scss']
})
export class ToastContainerComponent {

  constructor(public ucsAlertService: ToastAlertService) {
  }
}
