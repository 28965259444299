import {NgModule} from '@angular/core';
import {mapToCanActivate, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './shared/component/error/error.component';
import {AuthComponent} from './shared/component/auth/auth.component';
import {LogoutComponent} from './shared/component/logout/logout.component';
import {ForbiddenComponent} from './shared/component/forbidden/forbidden.component';
import {LoginGuard} from './shared/component/auth/login-guard/LoginGuard';
import {LegalNoticeComponent} from './shared/component/footer/legal-notice/legal-notice.component';
import {ContactComponent} from './shared/component/footer/contact/contact.component';
import {TermsComponent} from './shared/component/footer/terms/terms.component';
import {AuthFailureComponent} from './shared/component/auth/auth-failure.component';
import {BuyerGuard} from './shared/component/auth/buyer-guard/BuyerGuard';
import {DealerSettingsGuard} from './shared/component/auth/dealer-settings-guard/DealerSettingsGuard';
import {UserSettingsComponent} from './shared/component/user-settings/user-settings.component';
import {LandingPageComponent} from './landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule),
    title: 'UCS - Used Car Sales',
    canActivate: mapToCanActivate([LoginGuard, BuyerGuard])
  },
  {
    path: 'administration',
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
    title: 'UCS - Used Car Sales',
    canActivate: mapToCanActivate([DealerSettingsGuard])
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    title: 'UCS - Used Car Sales'
  },
  {
    path: 'error',
    component: ErrorComponent,
    title: 'Error'
  },
  {
    path: 'error/:id',
    component: ErrorComponent,
    title: 'Error'
  },
  {
    path: 'landingpage',
    component: LandingPageComponent,
    title: 'UCS - Landing page'
  },
  {
    path: 'logout',
    component: LogoutComponent,
    title: 'UCS - Logout'
  },
  {
    path: 'auth',
    component: AuthComponent,
    title: 'UCS - Authentication'
  },
  {
    path: 'auth/failure',
    component: AuthFailureComponent,
    title: 'Authentication failure'
  },
  {
    path: 'auth/:status',
    component: AuthComponent,
    title: 'Authentication status'
  },
  {
    path: 'auth/success',
    component: AuthComponent,
    title: 'Authentication success'
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    title: 'UCS - Forbidden'
  },
  {
    path: 'terms',
    component: TermsComponent,
    canActivate: mapToCanActivate([LoginGuard]),
    title: 'UCS - Terms'
  },
  {
    path: 'legal-notice',
    component: LegalNoticeComponent,
    canActivate: mapToCanActivate([LoginGuard]),
    title: 'UCS - Legal notice'
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: mapToCanActivate([LoginGuard]),
    title: 'UCS - Contact'
  },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    canActivate: mapToCanActivate([LoginGuard]),
    title: 'UCS - Used Car Sales'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'error/navigation',
    title: 'Error'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',

    }
  )],
  exports: [RouterModule]
})

export class AppRoutesModule {
}

