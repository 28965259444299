<div class="detail-container mt-3 ps-3 pe-3 pb-2 mb-xl-3" id="landingpage">
  <div class="horizontal-center">
    <svg height="33" viewBox="0 0 93 33" width="93" xmlns="http://www.w3.org/2000/svg">
      <g fill="$color-text-primary" fill-rule="evenodd">
        <path
            d="M81.3008,26.7714 L80.9708,24.7984 C81.7168,24.6744 82.7498,24.6254 83.9468,24.5684 C86.1488,24.4634 89.7718,24.2924 90.7148,23.3914 C90.3538,22.8164 90.1608,22.0064 90.5018,20.4504 C91.0868,17.7714 91.0438,17.6884 88.1818,15.9994 L87.9328,15.8514 C84.9918,14.1154 83.5158,13.2444 79.7408,12.1434 C77.1438,11.3874 73.1558,10.9584 69.9528,10.6154 C68.3648,10.4444 66.9938,10.2964 66.1068,10.1394 C63.5338,9.6854 58.7988,7.2864 54.5638,5.0734 C54.2478,4.9084 53.9408,4.7424 53.6338,4.5764 C50.7258,3.0024 47.9808,1.5104 38.8708,2.1434 C28.4518,2.8584 20.1498,6.0454 15.6888,7.7574 C12.5078,8.9774 9.6028,9.8974 7.6798,10.5064 C6.6198,10.8424 5.7828,11.1074 5.4958,11.2354 C4.8058,11.5424 2.2158,13.1414 2.1328,15.3774 C2.1148,15.8694 2.0918,16.3414 2.0688,16.7924 C1.9818,18.5144 1.8988,20.1414 2.3528,20.6754 C2.4738,20.8174 2.5758,20.9494 2.6728,21.0724 C3.1438,21.6724 3.3788,21.9734 6.1918,22.5204 C7.3918,22.7544 10.2878,23.2064 16.2128,23.5954 L16.0818,25.5914 C11.6088,25.2964 7.9598,24.9034 5.8098,24.4834 C2.5848,23.8574 1.9268,23.3634 1.0988,22.3054 C1.0168,22.2014 0.9308,22.0894 0.8288,21.9704 C-0.1382,20.8324 -0.0462,19.0044 0.0718,16.6924 C0.0938,16.2504 0.1168,15.7864 0.1348,15.3044 C0.2528,12.0714 3.4578,9.9524 4.6838,9.4084 C5.0718,9.2344 5.8688,8.9824 7.0748,8.6004 C8.9758,7.9984 11.8468,7.0884 14.9718,5.8904 C19.5298,4.1404 28.0158,0.8834 38.7348,0.1474 C48.4168,-0.5146 51.5548,1.1764 54.5858,2.8174 C54.8838,2.9784 55.1828,3.1394 55.4888,3.2994 C58.4528,4.8494 63.9708,7.7324 66.4538,8.1704 C67.2758,8.3154 68.6148,8.4594 70.1668,8.6254 C73.4478,8.9784 77.5328,9.4174 80.2998,10.2234 C84.3168,11.3934 85.9618,12.3654 88.9488,14.1284 L89.1978,14.2764 C92.5718,16.2684 93.2778,17.1164 92.4548,20.8774 C92.1918,22.0854 92.3808,22.3094 92.4728,22.4174 C93.0088,23.0514 92.9988,23.6764 92.4398,24.4464 C91.1428,26.2294 87.3698,26.4084 84.0408,26.5664 C82.9548,26.6184 81.9298,26.6664 81.3008,26.7714"/>
        <path
            d="M30.7695 15.6689L28.7525 25.8219C28.6325 26.4219 28.5375 26.9499 28.5375 27.4539 28.5375 29.2779 29.5695 30.3109 31.3445 30.3109 33.6975 30.3109 34.9935 29.0149 35.6415 25.7499L37.6575 15.6689 40.2495 15.6689 38.2335 25.7739C37.2255 30.7429 35.2095 32.7109 31.1535 32.7109 27.8885 32.7109 26.0165 30.9109 26.0165 27.6219 26.0165 26.9269 26.0645 26.1829 26.2565 25.2709L28.1765 15.6689 30.7695 15.6689zM41.4482 25.9658C41.4482 20.1338 44.8812 15.3818 49.7292 15.3818 52.5612 15.3818 54.4332 16.7258 55.2252 19.3178L52.8972 20.3258C52.4182 18.6938 51.4572 17.7098 49.5132 17.7098 47.0892 17.7098 45.3122 19.5578 44.7372 22.3898L44.1842 25.1028C44.0642 25.6538 44.0412 26.1108 44.0412 26.5188 44.0412 28.8948 45.2642 30.3828 47.4252 30.3828 49.5612 30.3828 50.6892 29.2788 51.6492 27.5268L53.8092 28.5588C52.3452 31.3908 50.1852 32.7108 47.2332 32.7108 43.8482 32.7108 41.4482 30.3828 41.4482 25.9658M54.9844 29.7109L56.9764 28.1979C57.8884 29.7339 59.1844 30.3829 60.9374 30.3829 63.4334 30.3829 64.7774 29.0379 64.7774 27.3109 64.7774 26.0629 63.9854 25.3659 62.4974 25.0549L61.1054 24.7899C58.7284 24.2859 57.2894 22.9179 57.2894 20.5179 57.2894 17.6609 59.5924 15.3819 63.3614 15.3819 65.9054 15.3819 67.7774 16.3419 68.9064 17.8539L67.0344 19.5099C66.1704 18.3339 65.0424 17.7099 63.2414 17.7099 61.2014 17.7099 59.9054 18.6929 59.9054 20.3019 59.9054 21.5499 60.6494 22.1979 62.1374 22.5099L63.5054 22.7739C65.9544 23.2779 67.4414 24.6699 67.4414 27.0949 67.4414 30.0469 65.0654 32.7109 60.9134 32.7109 58.3214 32.7109 56.1124 31.8469 54.9844 29.7109"/>
      </g>
    </svg>
  </div>


  <div class="row my-2 horizontal-center">
    <span class="title">{{ 'landing-page.heading' | translate }}</span>
  </div>

  <div class="row my-2 horizontal-center provider-button">
    <button (click)="login('PNET_AUTHENTICATION')" class="btn-lg btn-primary custom">
      {{ 'landing-page.pnet' | translate }}
      @if (this.authProvider === 'PNET_AUTHENTICATION') {
        <svg
            class="active-provider"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
          <path fill-rule="evenodd"
                d="M18.18,21.9741 C18.021,21.9741 17.861,21.9361 17.715,21.8591 L12,18.8541 L6.285,21.8591 C5.95,22.0361 5.541,22.0071 5.232,21.7831 C4.924,21.5591 4.77,21.1801 4.834,20.8051 L5.925,14.4431 L1.302,9.9401 C1.03,9.6751 0.931,9.2781 1.049,8.9151 C1.166,8.5541 1.479,8.2891 1.855,8.2351 L8.246,7.3011 L11.104,1.5121 C11.44,0.8291 12.56,0.8291 12.896,1.5121 L15.754,7.3011 L22.145,8.2351 C22.521,8.2891 22.834,8.5541 22.951,8.9151 C23.069,9.2781 22.97,9.6751 22.698,9.9401 L18.075,14.4431 L19.166,20.8051 C19.23,21.1801 19.076,21.5591 18.768,21.7831 C18.594,21.9101 18.387,21.9741 18.18,21.9741"/>
        </svg>
      }
      @if (this.authProvider === 'GRP_AUTHENTICATION') {
        <svg
            class="active-provider"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
          <path fill-rule="evenodd"
                d="M12,16.7241 C12.16,16.7241 12.32,16.7621 12.465,16.8391 L16.852,19.1461 L16.014,14.2631 C15.958,13.9391 16.066,13.6071 16.302,13.3781 L19.852,9.9211 L14.945,9.2031 C14.62,9.1561 14.339,8.9521 14.193,8.6571 L12,4.2141 L9.807,8.6571 C9.661,8.9521 9.38,9.1561 9.055,9.2031 L4.148,9.9211 L7.698,13.3781 C7.934,13.6071 8.042,13.9391 7.986,14.2631 L7.148,19.1461 L11.535,16.8391 C11.68,16.7621 11.84,16.7241 12,16.7241 M18.18,21.9741 C18.021,21.9741 17.861,21.9361 17.715,21.8591 L12,18.8541 L6.285,21.8591 C5.95,22.0361 5.541,22.0071 5.232,21.7831 C4.924,21.5591 4.77,21.1801 4.834,20.8051 L5.925,14.4431 L1.302,9.9401 C1.03,9.6751 0.931,9.2781 1.049,8.9151 C1.166,8.5541 1.479,8.2891 1.855,8.2351 L8.246,7.3011 L11.104,1.5121 C11.44,0.8291 12.56,0.8291 12.896,1.5121 L15.754,7.3011 L22.145,8.2351 C22.521,8.2891 22.834,8.5541 22.951,8.9151 C23.069,9.2781 22.97,9.6751 22.698,9.9401 L18.075,14.4431 L19.166,20.8051 C19.23,21.1801 19.076,21.5591 18.768,21.7831 C18.594,21.9101 18.387,21.9741 18.18,21.9741"/>
        </svg>
      }
    </button>
  </div>

  <div class="row my-2 horizontal-center provider-button">
    <button (click)="login('GRP_AUTHENTICATION')" class="btn-lg btn-primary custom">
      {{ 'landing-page.grp' | translate }}
      @if (this.authProvider === 'GRP_AUTHENTICATION') {
        <svg
            class="active-provider"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
          <path fill-rule="evenodd"
                d="M18.18,21.9741 C18.021,21.9741 17.861,21.9361 17.715,21.8591 L12,18.8541 L6.285,21.8591 C5.95,22.0361 5.541,22.0071 5.232,21.7831 C4.924,21.5591 4.77,21.1801 4.834,20.8051 L5.925,14.4431 L1.302,9.9401 C1.03,9.6751 0.931,9.2781 1.049,8.9151 C1.166,8.5541 1.479,8.2891 1.855,8.2351 L8.246,7.3011 L11.104,1.5121 C11.44,0.8291 12.56,0.8291 12.896,1.5121 L15.754,7.3011 L22.145,8.2351 C22.521,8.2891 22.834,8.5541 22.951,8.9151 C23.069,9.2781 22.97,9.6751 22.698,9.9401 L18.075,14.4431 L19.166,20.8051 C19.23,21.1801 19.076,21.5591 18.768,21.7831 C18.594,21.9101 18.387,21.9741 18.18,21.9741"/>
        </svg>
      }
      @if (this.authProvider === 'PNET_AUTHENTICATION') {
        <svg
            class="active-provider"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
          <path fill-rule="evenodd"
                d="M12,16.7241 C12.16,16.7241 12.32,16.7621 12.465,16.8391 L16.852,19.1461 L16.014,14.2631 C15.958,13.9391 16.066,13.6071 16.302,13.3781 L19.852,9.9211 L14.945,9.2031 C14.62,9.1561 14.339,8.9521 14.193,8.6571 L12,4.2141 L9.807,8.6571 C9.661,8.9521 9.38,9.1561 9.055,9.2031 L4.148,9.9211 L7.698,13.3781 C7.934,13.6071 8.042,13.9391 7.986,14.2631 L7.148,19.1461 L11.535,16.8391 C11.68,16.7621 11.84,16.7241 12,16.7241 M18.18,21.9741 C18.021,21.9741 17.861,21.9361 17.715,21.8591 L12,18.8541 L6.285,21.8591 C5.95,22.0361 5.541,22.0071 5.232,21.7831 C4.924,21.5591 4.77,21.1801 4.834,20.8051 L5.925,14.4431 L1.302,9.9401 C1.03,9.6751 0.931,9.2781 1.049,8.9151 C1.166,8.5541 1.479,8.2891 1.855,8.2351 L8.246,7.3011 L11.104,1.5121 C11.44,0.8291 12.56,0.8291 12.896,1.5121 L15.754,7.3011 L22.145,8.2351 C22.521,8.2891 22.834,8.5541 22.951,8.9151 C23.069,9.2781 22.97,9.6751 22.698,9.9401 L18.075,14.4431 L19.166,20.8051 C19.23,21.1801 19.076,21.5591 18.768,21.7831 C18.594,21.9101 18.387,21.9741 18.18,21.9741"/>
        </svg>
      }
    </button>
  </div>

  <div class="row my-2 form-check">
    <input (click)="toggleSaveSelection()" [ngModel]="saveSelection" class="form-check-input" id="flexCheckDefault"
           type="checkbox">
    <label class="form-check-inline" for="flexCheckDefault">
      {{ 'landing-page.checkbox' | translate }}
    </label>
  </div>
</div>

