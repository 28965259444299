import {Inject, Injectable, OnDestroy} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../service/authentication.service';
import {takeUntil, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {UpdateLoadingAction} from '../../../store/spinner/spinner.actions';
import {APP_CONFIG} from '../../../misc/inject-tokens';
import {AppConfig} from '../../../model/app-config.model';
import {ucsIsNil} from '../../../misc/utils';
import {ToastAlertService} from '../../../service/toast-alert.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  userState: any;
  actualLocal: string;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private alert: ToastAlertService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.AppState>,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.shouldBypass(req)) {
      return next.handle(req);
    }

    return this.handleAuthenticatedRequest(req, next);
  }

  private handleAuthenticatedRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = this.addAuthorizationHeader(req);

    if (ucsIsNil(authReq)) {
      if (!this.config.production) {
        console.error(`BUG: a request fell through here unauthenticated. Variable authReq is null. This shouldn't happen.
         Rewriting with original request. Request was to url: ${req.url}`);
      }
      authReq = req;
    }

    return next.handle(authReq).pipe(
      tap(
        (value: HttpEvent<any>) => {
          // intentionally left empty
        },
        error => this.handleErrorResponse(error)
      )
    );
  }

  private addAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    let authReq = req;
    const local = this.getLocale();
    if (this.authenticationService.supportsLocalStorage() && this.authenticationService.isAuthenticated()) {
      const token = this.authenticationService.getToken();
      authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token).set('Accept-Language', local),
      });
    } else {
      this.handleLocalStorageUnsupported();
    }
    return authReq;
  }

  private handleErrorResponse(error: any) {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      this.fallbackDisableSpinner();
      window.location.href = this.config.authenticationUrl + '/authentication/logon';
    }
  }

  private handleLocalStorageUnsupported() {
    this.alert.danger(this.translate.instant('error.localstorage-not-supported'));
    this.router.navigateByUrl('logout');
  }

  private getLocale(): string {
    let locale = 'en';

    this.store.select(fromRoot.getUserState).pipe(takeUntil(this.unsubscribe)).subscribe(userState => {
      if (userState?.local) {
        this.userState = userState;
        locale = this.userState.local;
      }
    });

    return locale;
  }

  private shouldBypass(req: HttpRequest<any>): boolean {
    const urlsToBypass = [
      this.config.salesApiUrl + '/translations',
      this.config.authenticationUrl + '/actuator/info',
      this.config.authenticationUrl + '/authentication/logon',
    ];
    return urlsToBypass.some(url => req.url.startsWith(url)) ||
      (!req.url.startsWith(this.config.authenticationUrl) && !req.url.startsWith(this.config.salesApiUrl)
          && !req.url.startsWith(this.config.salesVmsApiUrl));
  }

  /**
   * just in case the spinner state was set true directly without using the spinner service
   */
  fallbackDisableSpinner(): void {
    this.store.dispatch(new UpdateLoadingAction(false));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
