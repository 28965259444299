import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map, switchMap, tap} from 'rxjs/operators';
import * as UserActions from './notification.actions';
import {
  UpdateChannelNotificationAction,
  UpdatedChannelNotificationAction,
  UpdatedUserNotificationAction,
  UpdateUserNotificationAction
} from './notification.actions';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthenticationService} from '../../service/authentication.service';
import {NotificationService} from '../../service/notification.service';
import {SpinnerService} from '../../service/spinner.service';
import * as fromRoot from '../app.reducers';

/**
 * Effects contain the side effects for asynchronous calls so that our components can remain compact
 * We are listening on the Observable of all user actions until UserActions.UPDATE_USER_INFO is dispatched
 * on which we act here to call the AuthenticationService to fetch the new user info from the rest endpoint
 * We will then dispatch our own UserActions.UPDATED_USER_INFO which will be processed in the reducer
 *
 * If the call returns an error (e.g redirect due to authorization errors)
 * we clear the authorization token in the local storage
 */
@Injectable()
export class NotificationEffects {

  appScope: AppScope;

  updateUserNotifications$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.UPDATE_USER_NOTIFICATION))
      .pipe(map((action: UpdateUserNotificationAction) => action))
      .pipe(tap(payload => this.appScope = payload.appScope))
      .pipe(
        switchMap(() =>
          this.notificationService.getUserNotifications(this.appScope)
            .pipe(map(data => new UpdatedUserNotificationAction(data)))
        ))
  );

  updateChannelNotifications$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.UPDATE_CHANNEL_NOTIFICATION))
      .pipe(map((action: UpdateChannelNotificationAction) => action))
      .pipe(
        switchMap(() =>
          this.spinnerService.spinner(
            this.notificationService.getChannelNotifications()
              .pipe(map(data => new UpdatedChannelNotificationAction(data)))
          )))
  );

  constructor(
      private store: Store<fromRoot.AppState>,
      private authenticationService: AuthenticationService,
      private notificationService: NotificationService,
      private actions$: Actions,
      private spinnerService: SpinnerService) {
  }
}
