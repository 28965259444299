<div class="ucs-toast-container position-fixed end-0" data-cy="notification">
  @for (toast of ucsAlertService.toastAlerts; track toast) {
    <ngb-toast [autohide]="toast.autohide" [ngClass]="toast.type">
      <ng-template ngbToastHeader>
        <div class="me-auto text-white my-2">
          <ucs-icon [shape]="toast.iconShape" [class]="'white'" [size]="30"></ucs-icon>
          <span class="ps-3">{{toast.message}}</span>
        </div>
      </ng-template>
    </ngb-toast>
  }
</div>
