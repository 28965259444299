import {first, map, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {OfferService} from '../../service/offer.service';
import {SpinnerService} from '../../service/spinner.service';
import * as fromRoot from '../app.reducers';
import * as EnforcedAuctionOfferAction from './enforced-auction-offer.actions';
import * as SpinnerActions from '../spinner/spinner.actions';
import {SearchService} from '../../service/search.service';

/**
 * Effects contain the side effects for asynchronous calls so that our components can remain compact
 * We are listening on the Observable of all offer actions until OfferActions.UPDATE_OFFER is dispatched
 * on which we act here to call the OfferService to fetch the new offer from the rest endpoint
 * We will then dispatch our own OfferActions.UPDATED_AMOUNT which will be processed in the reducer
 */
@Injectable()
export class EnforcedAuctionOfferEffects {
  size: number;
  page: number;
  sort: string;
  order: string;
  id: number; // used for UpdateOfferAction

  updateOffers$: Observable<Action> = createEffect(
    () => this.actions$.pipe(ofType(EnforcedAuctionOfferAction.UPDATE_OFFERS))
      .pipe(switchMap(() =>
        this.store.select(fromRoot.getEnforcedAuctionOfferPageSettings).pipe(first())
          .pipe(
            switchMap((page) =>
              this.offerService.getEnforcedAuctionOffers(page.size, page.page, page.sort, page.order)
                .pipe(map(data => new EnforcedAuctionOfferAction.UpdatedOffersAction(data)))
            )
          ))
      )
  );

  updateChannelOffersPageOnSearchAggregationChange$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(EnforcedAuctionOfferAction.UPDATE_SEARCH_AGGREGATION))
      .pipe(tap(() => this.store.dispatch(new EnforcedAuctionOfferAction.UpdatePageSettingsPage(0))))
      .pipe(
        switchMap(() =>
          this.store.select(fromRoot.getEnforcedAuctionOfferPageSettings).pipe(first())
            .pipe(
              switchMap((page) =>
                this.spinnerService.spinner(
                  this.offerService.getEnforcedAuctionOffers(page.size, 0, page.sort, page.order)
                    .pipe(
                      map(data => {
                        return new EnforcedAuctionOfferAction.UpdatedOffersAction(data);
                      })
                    ))))))
  );

  updateChannelOffersPageOnPageSettingChange$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(EnforcedAuctionOfferAction.UPDATE_PAGE_SETTINGS))
      .pipe(map((action: EnforcedAuctionOfferAction.UpdatePageSettings) => action.payload))
      .pipe(
        switchMap((page) =>
          this.spinnerService.spinner(
            this.offerService.getEnforcedAuctionOffers(page.size, page.page, page.sort, page.order)
              .pipe(
                map(data => {
                  return new EnforcedAuctionOfferAction.UpdatedOffersAction(data);
                })
              ))))
  );

  update$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(EnforcedAuctionOfferAction.UPDATE_SEARCH_AGGREGATION)).pipe(
      switchMap(() =>
        this.searchService
          .getEnforcedAuctionOfferSearchAggregations()
          .pipe(map(data => new EnforcedAuctionOfferAction.UpdatedSearchAggregationAction(data)))
      ))
  );

  updateLoadingActionOnUpdatedOffersChange$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(EnforcedAuctionOfferAction.UPDATED_OFFERS))
      .pipe((map(() => new SpinnerActions.UpdateLoadingAction(false))))
  );

  constructor(private offerService: OfferService,
              private searchService: SearchService,
              private store: Store<fromRoot.AppState>,
              private actions$: Actions,
              private spinnerService: SpinnerService) {
  }
}
