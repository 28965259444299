import { InjectionToken, FactoryProvider } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('window');

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

/**
 * Creates a FactoryProvider which is globally provided in AppModule
 * This is needed in later Angular-Versions to correctly provide the Window-DOM-Element
 * @type {FactoryProvider[]}
 */
export const windowProviders = [
  windowProvider
];

