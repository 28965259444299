import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {map, switchMap, tap} from 'rxjs/operators';
import * as UserActions from './user-settings.actions';
import {
  UpdatedUserSettingsAction,
  UpdateUserSettingsAction
} from './user-settings.actions';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NotificationService} from '../../../service/notification.service';
import {UserSettingsService} from '../../../service/user-settings.service';
import {AuthenticationService} from '../../../service/authentication.service';
import * as fromRoot from '../../app.reducers';

/**
 * Effects for handling UserSettingsActions
 */
@Injectable()
export class UserSettingsEffects {

  userId: number;

  updateUserSettings$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.UPDATE_USER_SETTINGS))
      .pipe(map((action: UpdateUserSettingsAction) => action))
      .pipe(tap(payload => this.userId = payload.userId))
      .pipe(
        switchMap(() =>
          this.userSettingsService.getAllUserSettingsFromBackend(this.userId)
            .pipe(map(data => new UpdatedUserSettingsAction(data)))
        ))
  );

  constructor(
    private store: Store<fromRoot.AppState>,
    private authenticationService: AuthenticationService,
    private userSettingsService: UserSettingsService,
    private notificationService: NotificationService,
    private actions$: Actions
  ) {
  }
}
