<ucs-spinner></ucs-spinner>
<div class="page-container position-relative">
  <header class="ucs-header" id="top">
    <div class="container-fluid">
      <div [ngClass]="{'no-menu': !showTopMenuAndFooter}" class="row mb-3">
        @if (!showTopMenuAndFooter) {
          <ucs-icon [shape]="'ucs_logo'" [class]="'white'"></ucs-icon>
        }
        @if (showTopMenuAndFooter) {
          <ucs-top-menu class="flex-fill">
          </ucs-top-menu>
        }
      </div>
    </div>
  </header>

  <ucs-toast-container></ucs-toast-container>
  <div class="container-fluid">
    @if (showTopMenuAndFooter && userInfo?.accredited === false
    && userInfo?.enabledChannels?.includes('PIA')) {
      <ucs-document-missing-message
      ></ucs-document-missing-message>
    }
  </div>
  <div class="container-fluid">
    @if (userInfo?.locked) {
      <ucs-dealer-locked-message [lockReason]="userInfo?.lockReason"
                                 class="col col-12"></ucs-dealer-locked-message>
    }
  </div>
  @if (alertStore.isActive()) {
    <div class="bar-alert-container">
      <ngb-alert class="px-2 text-center bar-alert" [dismissible]="alertStore.alert().isDismissible"
                 [type]="alertStore.alert().type" (closed)="close()">
        {{ alertStore.alert().message }}
      </ngb-alert>
    </div>
  }
  <div [ngClass]="{'alert-margin': alertStore.isActive()}" class="container-fluid main-content" id="ucs-content">
    <router-outlet></router-outlet>
  </div>

  @if (!(getBrowserName() === 'chrome' || getBrowserName() === 'chromium based')) {
    <div>
      <ucs-browser-alert-dialog></ucs-browser-alert-dialog>
    </div>
  }

  @if (stage) {
    <div class="stage-info"
         [ngClass]="{
     'dev':stage ==='local' || stage === 'dev',
     'qa' :stage === 'qa',
     'demo' :stage === 'demo',
     'prod': stage === 'prod'
     }">
      <h1>{{ stage }}</h1>
    </div>
  }

  <footer class="sticky-footer position-absolute">
    <div [ngClass]="{'no-menu': !showTopMenuAndFooter}" class="container-fluid footer">
      @if (!showTopMenuAndFooter) {
        <div class="col-auto text text-big">
          <strong>&copy; UCS {{ currentYear }}</strong></div>
      }
      @if (showTopMenuAndFooter) {
        <ucs-footer class="flex-fill"></ucs-footer>
      }
    </div>
  </footer>
</div>
