import {map, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as OfferActions from './offer.actions';
import {
  UpdateBookmarkedOffersAllUcAction,
  UpdateBookmarkedOffersDinBilAction,
  UpdateBookmarkedOffersPbAction,
  UpdateBookmarkedOffersPiaAction,
  UpdateBookmarkedOffersVgrxAction,
  UpdateCurrentUserCompanyOffersAllUcAction,
  UpdateCurrentUserCompanyOffersDinBilAction,
  UpdateCurrentUserCompanyOffersPbAction,
  UpdateCurrentUserCompanyOffersPiaAction,
  UpdateCurrentUserCompanyOffersVgrxAction,
  UpdatedBookmarkedOffersAllUcAction,
  UpdatedBookmarkedOffersDinBilAction,
  UpdatedBookmarkedOffersPbAction,
  UpdatedBookmarkedOffersPiaAction,
  UpdatedBookmarkedOffersVgrxAction,
  UpdatedCurrentUserCompanyOffersAllUcAction,
  UpdatedCurrentUserCompanyOffersDinBilAction,
  UpdatedCurrentUserCompanyOffersPbAction,
  UpdatedCurrentUserCompanyOffersPiaAction,
  UpdatedCurrentUserCompanyOffersVgrxAction,
  UpdatedFinishedUserCompanyOffersAllUcAction,
  UpdatedFinishedUserCompanyOffersDinBilAction,
  UpdatedFinishedUserCompanyOffersPbAction,
  UpdatedFinishedUserCompanyOffersPiaAction,
  UpdatedFinishedUserCompanyOffersVgrxAction,
  UpdatedOfferAction,
  UpdateFinishedUserCompanyOffersAllUcAction,
  UpdateFinishedUserCompanyOffersDinBilAction,
  UpdateFinishedUserCompanyOffersPbAction,
  UpdateFinishedUserCompanyOffersPiaAction,
  UpdateFinishedUserCompanyOffersVgrxAction,
  UpdateOfferAction,
  UpdatePageSettingsChannel
} from './offer.actions';
import * as SpinnerActions from '../spinner/spinner.actions';
import {OfferService} from '../../service/offer.service';
import {SpinnerService} from '../../service/spinner.service';
import * as SearchActions from '../search/search.actions';
import {SearchService} from '../../service/search.service';
import * as fromRoot from '../app.reducers';

/**
 * Effects contain the side effects for asynchronous calls so that our components can remain compact
 * We are listening on the Observable of all offer actions until OfferActions.UPDATE_OFFER is dispatched
 * on which we act here to call the OfferService to fetch the new offer from the rest endpoint
 * We will then dispatch our own OfferActions.UPDATED_AMOUNT which will be processed in the reducer
 */
@Injectable()
export class OfferEffects {
  size: number;
  page: number;
  sort: string;
  order: string;
  id: number; // used for UpdateOfferAction
  channel: DistributionChannel;

  updateOffer$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_OFFER))
      .pipe(map((action: UpdateOfferAction) => action))
      .pipe(tap(payload => this.id = payload.id))
      .pipe(switchMap(() =>
        this.offerService
          .getOffer(this.id)
          .pipe(map(data => new UpdatedOfferAction(data)))
      ))
  );

  // This is an effect handling purely side effects, and does not update the store in the end, as effects usually do.
  // This can be achieved with the "dispatch: false" config.
  updateSearchAggregationOnPageSettingsChannelChange$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_PAGE_SETTINGS_CHANNEL))
      .pipe(map((action: UpdatePageSettingsChannel) => action))
      .pipe(tap((action) => {
        if (this.channel !== action.channel) {
          this.channel = action.channel;
          this.store.dispatch(new SpinnerActions.UpdateLoadingAction(true));
          this.store.dispatch(new SearchActions.UpdateSearchAggregationAction());
        }
      })),
    {dispatch: false}
  );

  updateBookmarkedOffersPIA$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_BOOKMARKED_OFFERS_PIA))
      .pipe(map((action: UpdateBookmarkedOffersPiaAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getBookmarkedOffersForChannel('PIA', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedBookmarkedOffersPiaAction(data))))
      ))
  );

  updateBookmarkedOffersPB$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_BOOKMARKED_OFFERS_PB))
      .pipe(map((action: UpdateBookmarkedOffersPbAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getBookmarkedOffersForChannel('PB', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedBookmarkedOffersPbAction(data))))
      ))
  );

  updateBookmarkedOffersALLUC$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_BOOKMARKED_OFFERS_ALLUC))
      .pipe(map((action: UpdateBookmarkedOffersAllUcAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getBookmarkedOffersForChannel('ALL_UC', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedBookmarkedOffersAllUcAction(data))))
      ))
  );

  updateBookmarkedOffersDINBIL$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_BOOKMARKED_OFFERS_DINBIL))
      .pipe(map((action: UpdateBookmarkedOffersDinBilAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getBookmarkedOffersForChannel('DIN_BIL', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedBookmarkedOffersDinBilAction(data))))
      ))
  );

  updateBookmarkedOffersVGRX$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_BOOKMARKED_OFFERS_VGRX))
      .pipe(map((action: UpdateBookmarkedOffersVgrxAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getBookmarkedOffersForChannel('VGRX', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedBookmarkedOffersVgrxAction(data))))
      ))
  );

  updateCurrentUserCompanyOffersPIA$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_CURRENT_USERCOMPANY_OFFERS_PIA))
      .pipe(map((action: UpdateCurrentUserCompanyOffersPiaAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getCurrentUserCompanyOffersForChannel('PIA', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedCurrentUserCompanyOffersPiaAction(data))))
      ))
  );

  updateCurrentUserCompanyOffersPB$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_CURRENT_USERCOMPANY_OFFERS_PB))
      .pipe(map((action: UpdateCurrentUserCompanyOffersPbAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getCurrentUserCompanyOffersForChannel('PB', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedCurrentUserCompanyOffersPbAction(data))))
      ))
  );

  updateCurrentUserCompanyOffersALLUC$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_CURRENT_USERCOMPANY_OFFERS_ALLUC))
      .pipe(map((action: UpdateCurrentUserCompanyOffersAllUcAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getCurrentUserCompanyOffersForChannel('ALL_UC', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedCurrentUserCompanyOffersAllUcAction(data))))
      ))
  );

  updateCurrentUserCompanyOffersDINBIL$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_CURRENT_USERCOMPANY_OFFERS_DINBIL))
      .pipe(map((action: UpdateCurrentUserCompanyOffersDinBilAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getCurrentUserCompanyOffersForChannel('DIN_BIL', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedCurrentUserCompanyOffersDinBilAction(data))))
      ))
  );

  updateCurrentUserCompanyOffersVGRX$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_CURRENT_USERCOMPANY_OFFERS_VGRX))
      .pipe(map((action: UpdateCurrentUserCompanyOffersVgrxAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getCurrentUserCompanyOffersForChannel('VGRX', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedCurrentUserCompanyOffersVgrxAction(data))))
      ))
  );

  updateFinishedUserCompanyOffersPIA$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_FINISHED_USERCOMPANY_OFFERS_PIA))
      .pipe(map((action: UpdateFinishedUserCompanyOffersPiaAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getFinishedUserCompanyOffersForChannel('PIA', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedFinishedUserCompanyOffersPiaAction(data))))
      ))
  );

  updateFinishedUserCompanyOffersPB$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_FINISHED_USERCOMPANY_OFFERS_PB))
      .pipe(map((action: UpdateFinishedUserCompanyOffersPbAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getFinishedUserCompanyOffersForChannel('PB', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedFinishedUserCompanyOffersPbAction(data))))
      ))
  );

  updateFinishedUserCompanyOffersALLUC$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_FINISHED_USERCOMPANY_OFFERS_ALLUC))
      .pipe(map((action: UpdateFinishedUserCompanyOffersAllUcAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getFinishedUserCompanyOffersForChannel('ALL_UC', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedFinishedUserCompanyOffersAllUcAction(data))))
      ))
  );

  updateFinishedUserCompanyOffersDINBIL$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_FINISHED_USERCOMPANY_OFFERS_DINBIL))
      .pipe(map((action: UpdateFinishedUserCompanyOffersDinBilAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getFinishedUserCompanyOffersForChannel('DIN_BIL', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedFinishedUserCompanyOffersDinBilAction(data))))
      ))
  );

  updateFinishedUserCompanyOffersVGRX$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(OfferActions.UPDATE_FINISHED_USERCOMPANY_OFFERS_VGRX))
      .pipe(map((action: UpdateFinishedUserCompanyOffersVgrxAction) => action))
      .pipe(tap(payload => {
        this.page = payload.page;
        this.size = payload.size;
        this.sort = payload.sort;
        this.order = payload.order;
      }))
      .pipe(switchMap(() =>
        this.spinnerService.spinner(
          this.offerService
            .getFinishedUserCompanyOffersForChannel('VGRX', this.size, this.page, this.sort, this.order)
            .pipe(map(data => new UpdatedFinishedUserCompanyOffersVgrxAction(data))))
      ))
  );

  constructor(private offerService: OfferService,
              private searchService: SearchService,
              private store: Store<fromRoot.AppState>,
              private actions$: Actions,
              private spinnerService: SpinnerService) {
  }
}
